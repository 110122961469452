@import '~antd/dist/antd.less';
@import './colors.less';
@import './colors-in-use.less';
/**
 * TODO: Find a better way to share variables from colors.ts between
 * *.less and *.ts files. Until then we keep a copy of the files in this folder.
 * Antd variables file to be overwritten: 
 * https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */

///////////////////////////////////////////////////////////////////////////////////
// Variables overrides - use colors.less and colors-in-use.less variables for overriding
///////////////////////////////////////////////////////////////////////////////////
// -------- Colors -----------
@primary-color: @colorBase-Brand;
// @info-color: @colorFeedback-Info;
// @success-color: @colorFeedback-Success;
// @processing-color: @colorFeedback-Info;
// @error-color: @colorFeedback-Danger;
// @highlight-color: @colorBackground-AccentPrimary;
// @warning-color: @colorFeedback-Warning;
// @white: @colorBase-White;
// @black: @colorBase-Black;

// -------- Font ------------
// @font-size-base: 17px;
@text-color: @colorTypography-Primary;
@heading-color: @colorTypography-Primary;

// -------- Layout -----------
// @layout-body-background: @colorBase-White;

// -------- Link -----------
// @link-color: @colorTypography-Link;
// @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
// @link-active-color: color(~`colorPalette('@{link-color}', 7) `);

// -------- Background color -------
// @item-hover-bg: @colorBackground-Tertiary;

// -------- Border color -----------
// @border-color-base: @colorBorder-DividerPrimary; // base border outline a component
// @border-color-split: @colorBorder-DividerPrimary; // split border inside a component

// -------- Border -------------
// @border-primary: 1px solid @colorBorder-DividerPrimary;
// @border-secondary: 1px solid @colorBorder-DividerSecondary;

///////////////////////////////////////////////////////////////////////////////////
// Genral overrides
///////////////////////////////////////////////////////////////////////////////////
html {
  // This is for the after glow effect when i.e. clicking a button
  --antd-wave-shadow-color: @colorBase-Blue;
}
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

///////////////////////////////////////////////////////////////////////////////////
// Component overrides
///////////////////////////////////////////////////////////////////////////////////
/**
 * Button
 */
// @btn-border-radius-base: 4px;
// @btn-border-width: 0px;
// .ant-btn {
//   font-size: 15px;
//   line-height: 20px;
//   letter-spacing: 0.02em;
//   padding: 6px 16px;
// }

// Disabled
// @btn-disable-border: transparent;
// @btn-disable-color: @color-Grey300;
// @btn-disable-bg: @color-Grey50;

/**
 * Collapse
 */
// @collapse-content-padding: 0;
// @collapse-panel-border-radius: 0;
// .ant-collapse-header {
//   font-size: 15px;
//   line-height: 20px;
//   letter-spacing: 0.08em;
//   font-variant: small-caps;
//   // color: @colorTypography-Heading !important;
//   color: @color-Grey400 !important;
// }

/**
* Dropdown
 */
// @dropdown-line-height: 24px;
// @dropdown-vertical-padding: 12px;
// @dropdown-horizontal-padding: 12px;
// .ant-dropdown-menu {
//   &:extend(.squad8-elevation-strong);
//   border: @border-primary;
//   padding: 0;
//   width: 224px;
//   border-radius: 8px;
//   overflow: hidden;

//   .ant-dropdown-menu-item {
//     display: flex;
//     .anticon {
//       font-size: 24px;
//     }
//   }
// }
// .ant-dropdown-menu-item-divider {
//   margin: 0;
// }

/**
 * Input
 */
// @input-padding-horizontal: 0;
// @input-padding-vertical-base: 0;
// .ant-input {
//   border: 0;

//   &:focus {
//     box-shadow: none;
//   }
// }

/**
 * Menu
 */
// @menu-highlight-danger-color: @error-color;
// @menu-item-active-danger-bg: @error-color;

/**
 * Modal
 */
// @modal-mask-bg: @color-WhiteOpacity05;
// @modal-header-bg: transparent;
// @modal-header-close-size: 48px;
// @modal-header-border-color-split: transparent;
// // @modal-footer-padding-vertical: 0;
// // @modal-footer-padding-horizontal: 0;

// .ant-modal-content {
//   &:extend(.squad8-elevation-strong);
//   border: @border-primary;
// }

/** 
 * Tag
 */
// @tag-default-bg: @colorBackground-SurfaceAccent;
// @tag-default-color: @primary-color;
// @tag-font-size: 15px;
// @tag-line-height: 20px;
// .ant-tag {
//   border: 0;
//   padding: 4px 8px;
//   border-radius: 4px;
//   margin-bottom: 8px;
// }

/**
 * Tooltip
 */
// @tooltip-arrow-width: 0;
// // @tooltip-color: @color-Fog20;
// // @tooltip-bg: @color-Viridian5;

// .ant-tooltip-inner {
//   padding: 6px 12px;
//   font-size: 12px;
//   line-height: 20px;
//   font-weight: 500;
// }

// /**
//  * Popover
//  */
// .ant-popover-inner {
//   // background-color: @color-Fog20;
//   border-radius: 8px;
//   .ant-popover-inner-content {
//     padding: 0;
//   }
// }
